<template>
<div id="bannerManage">
   <!--头部搜索-->
   <div class="bg-white searchDom">
     <div class="flex align-center justify-end margin-bottom-24 header_search">
       <el-button @click="addDialogVisible=true" style="height: 32px;width: 120px;padding: 0;color: #FFFFFF;background-color: #4E9F5B;">添加轮播图</el-button>
       <el-button @click="exportData" style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;">导出</el-button>
     </div>
   </div>
  <!-- 搜索列表 -->
  <div class="searchList">
     <div class="card">                        
        <div class="card-block table-border-style">
            <div class="table-responsive">
                <Empty v-show="dataList.length=='0'"/>
                <el-table v-show="dataList.length!='0'"
                   :header-cell-style="{color:'#333333',fontsize:'14px'}" :data="dataList" stripe
                   style="width: 100%">
                   <el-table-column prop="slider_id" align="center" label="ID">
                   </el-table-column>
                   <el-table-column prop="slider_category" align="center" label="板块">
                     <template slot-scope="scope">
                       <span>{{scope.row.slider_category=='1'?'首页':'我的'}}</span>
                     </template>
                   </el-table-column>
                   <el-table-column prop="slider_image" align="center" label="轮播图">
                     <template slot-scope="scope">
                       <el-button @click="readInfo(scope.row.slider_image)" style="color: #4E9F5B !important;" type="text" size="small">查看
                       </el-button>
                     </template>
                   </el-table-column>
                   <el-table-column prop="slider_create_time" align="center" label="发布时间">
                     <template slot-scope="scope">
                       <span>{{scope.row.slider_create_time*1000 | getDate }}</span>
                     </template>
                   </el-table-column>
                   <el-table-column prop="slider_state" align="center" label="状态">
                     <template slot-scope="scope">
                       <span>{{scope.row.slider_state=='1'?'启用':'禁用 '}}</span>
                     </template>
                   </el-table-column>
                   <el-table-column fixed="right" label="操作" align="center">
                     <template slot-scope="scope">
                       <el-button style="color: #4E9F5B !important;" @click="editBanner_(scope.row)" type="text" size="small">编辑</el-button>
                       <el-button @click="deleteBanner(scope.row.slider_id)" style="color: #FF1A2E !important;" type="text" size="small">删除</el-button>
                     </template>
                   </el-table-column>
                </el-table>
            </div>
        </div>
      </div>
  </div>
  <!--分页-->
  <div class="pageNum" id="pageNum">
    <Pagination style="margin-top:20px"
    v-show="rows>1"
    :total="rows"
    @change = "getBannerList"
    :page.sync= "sendTab.page"
    :limit.sync="sendTab.limit"/>
  </div>

  <!-- 添加公告弹窗 -->
  <el-dialog id="addBanner" title="添加轮播图" :visible.sync="addDialogVisible" @close="closeAddDialog" :close-on-press-escape="false" 
    :close-on-click-modal="false">
    <el-form style="text-align: left;" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
        label-position="top" class="demo-ruleForm">
        <el-form-item label="发布模块" prop="slider_category">
          <el-select v-model="ruleForm.slider_category" placeholder="请选择">
            <el-option label="首页" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="轮播图" prop="file_id">
            <el-upload style="width:320px;height:160px;line-height:160px"
             class="avatar-uploader"
             action="https://oss-campus-print.yihuichuang.com/"
             ref="newUpload"
             :data="upfileData"
             :show-file-list="false"
             :on-success="handleAvatarSuccess"
             :with-credentials='false'
             :on-change="changeImg"
             :before-upload="beforeAvatarUpload">
             <img v-if="imageUrl" :src="imageUrl" class="avatar">
             <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </el-form-item>
        <el-form-item label="状态" prop="slider_state">
          <el-switch v-model="ruleForm.slider_state" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="submitForm('ruleForm')">提 交</el-button>
    </span>
  </el-dialog>
  <el-dialog id="addBanner" title="编辑轮播图" :visible.sync="editDialogVisible" @close="closeEditDialog" :close-on-press-escape="false" 
    :close-on-click-modal="false">
    <el-form style="text-align: left;" :model="ruleForm" :rules="rules" ref="bannerInfo" label-width="100px"
        label-position="top" class="demo-ruleForm">
        <el-form-item label="发布模块" prop="slider_category">
          <el-select v-model="bannerInfo.slider_category" placeholder="请选择">
            <el-option label="首页" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="轮播图" prop="file_id">
            <el-upload style="width:320px;height:160px;line-height:160px"
             class="avatar-uploader"
             ref="frontEdit"
             action="https://oss-campus-print.yihuichuang.com/"
             :data="upfileData"
             :show-file-list="false"
             :on-success="handleEditAvatarSuccess"
             :on-change="changeEditImg"
             :with-credentials='false'
             :before-upload="beforeAvatarUpload">
             <img v-if="bannerInfo.slider_image" :src="bannerInfo.slider_image" class="avatar">
             <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </el-form-item>
        <el-form-item label="状态" prop="slider_state">
          <el-switch  v-model="bannerInfo.slider_state" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="submitEditForm('ruleForm')">提 交</el-button>
    </span>
  </el-dialog>
  <!-- 查看轮播图 -->
  <el-dialog id="infoModelBox" title="查看轮播图" :visible.sync="showInfoModel" :close-on-press-escape='false' :close-on-click-modal='false'
    append-to-body :before-close="closeInfoModel" style="width: 640px;min-height: 320px;border-radius: 8px;padding: 24px !important;margin:0 auto">
      <div  class="solid padding-16 text-14 text-333 margin-top-24 radius-4 text-left">
        <img :src="imgContent" style="margin-bottom: 16px;margin-right: 10px;width: 100%" >
      </div>
  </el-dialog>
</div>
</template>
<script>
import {bannerList,bannerAdd,bannerDel,bannerEdit,fileFind,fileSave} from '@/api/http'
import Pagination from '@/components/pagination'
import SparkMD5 from 'spark-md5'
export default {
    data(){
      return{
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
        dataList: [], //表格数据
        sendTab:{
          page:1,
          limit:10,
          keyword:''
        },
        addNotice:{
          message_state:'1'
        },
        /* 添加轮播图弹窗*/
        addDialogVisible: false,
        //修改轮播图弹窗
        editDialogVisible: false,
        textarea: '',
        state:false,//状态
        addNotice:{
          message_state:'1'
        },
        editNotice:{
          message_state:'',
          message_content:'',
          message_id:''
        },
        editDialogVisible:false,
        currentNoticID:'',
        /* 信息弹窗参数*/
        showInfoModel:false,
        title:'内容',
        content:'',
        imgContent:'',
        ruleForm: {},
        rules: {},
        /* 上传图片组*/
        imageUrl: '', //上传图片
        //上传图片额为参数
        upfileData: {
          key: ''
        },
        fileMd5: '', //文件md5
        bannerInfo:'',
        sendData:{}
      }
    },
    created(){
        this.getBannerList()
    },
    methods:{
        editBanner_(info){
          console.log(info)
          this.editDialogVisible = true
          this.bannerInfo = info
        },
        closeEditDialog(){
          this.editDialogVisible = true
        },
        //文件上传成功之后的处理
        handleAvatarSuccess(res,file){
          this.save_file()
        },
        handleEditAvatarSuccess(res,file){
          this.save_editFile()
        },
        //上传文件之前的处理
        beforeAvatarUpload(file){
          console.log('上传前', file)
          let date = this.util.formatDate(new Date())
          let date_ = this.util.formatTime(new Date()).replace(/,/g, "")
          let randnum = (Math.random() * 9999999999).toFixed(0)
          let fileName = date_ + randnum
          this.upfileData.key = 'upload/xcx/' + date + '/' + fileName
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
          }
          return isLt2M;
        },
        /* 文件上传成功，保存文件*/
        save_editFile(file_uri) {
          fileSave({
            file_uri: this.upfileData.key
          }).then(res => {
            console.log('保存', res)
            if (res.code == '1') {
              this.sendData.file_id = res.data.file_id //文件id
            }
          })
        },
        //文件上传成功，保存文件
        save_file(file_uri){
          fileSave({file_uri:this.upfileData.key}).then(res=>{
            console.log('保存文件：',res.data)
            if(res.data.code == '1'){
              this.ruleForm.file_id = res.data.data.file_id
            }
          })
        },
        //选择重新上传轮播图文件
        changeImg(file){
          console.log(file)
          this.imageUrl = URL.createObjectURL(file.raw)
          //上传文件
          this.upfileImg(file)
        },
        /* 选择文件*/
        changeEditImg(file) {
          // this.imageUrl = URL.createObjectURL(file.raw);
          this.bannerInfo.slider_image = URL.createObjectURL(file.raw);
          this.upfileImg(file)
        },
        //编辑轮播图重新选择图片再上传提交
        submitEditForm(formName){
          this.sendData.slider_id=this.bannerInfo.slider_id
          if(!this.sendData.slider_category){
              this.sendData.slider_category=this.bannerInfo.slider_category
          }
          this.sendRequestEditBanner(formName)
        },
        sendRequestEditBanner(formName){
          bannerEdit(this.sendData).then(res=>{
            console.log('编辑轮播图',res.data)
            if(res.data.code == '1'){
              this.$message.success('编辑成功')
              this.editDialogVisible = false
              this.getBannerList()
            }else{
              this.$message.error(res.info)
            }
          })
        },
        closeEditDialog(){
          this.editDialogVisible = false
        },
        /* 计算文件md5*/
        upfileImg(file) {
          const _this = this
          var fileReader = new FileReader();
          //此处打印file可看到file下的raw为文件属性
          var dataFile = file.raw;
          let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
          var spark = new SparkMD5.ArrayBuffer();
          //获取文件二进制数据
          fileReader.readAsArrayBuffer(dataFile)
          //异步执行函数
          fileReader.onload = function(e) {
            spark.append(e.target.result);
            var md5 = spark.end()
            console.log('MD5', md5)
            /* 查找文件是否存在*/
            _this.find_file(md5)
          }
        },
        /* 计算文件md5*/
        upfileEditImg(file) {
          const _this = this
          var fileReader = new FileReader();
          //此处打印file可看到file下的raw为文件属性
          var dataFile = file.raw;
          let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
          var spark = new SparkMD5.ArrayBuffer();
          //获取文件二进制数据
          fileReader.readAsArrayBuffer(dataFile)
          //异步执行函数
          fileReader.onload = function(e) {
            spark.append(e.target.result);
            var md5 = spark.end()
            console.log('MD5', md5)
            /* 查找文件是否存在*/
            _this.find_editFile(md5)
          }
        },
        /* 检查文件是否存在*/
        find_editFile(sparkEnd) {
          fileFind({
            file_md5: sparkEnd
          }).then(res => {
            console.log('检查', res)
            if (res.code == '1') { //文件存在，无需上传
              this.sendData.file_id = res.data.file_id //文件id
            } else { //文件不存在，上传文件
              this.$refs['frontEdit'].submit()
            }
          })
        },

        /* 检查文件是否存在*/
        find_file(sparkEnd) {
          fileFind({
            file_md5: sparkEnd
          }).then(res => {
            console.log('检查', res)
            if (res.data.code == '1') { //文件存在，无需上传
              this.ruleForm.file_id = res.data.data.file_id //文件id
            } else { //文件不存在，上传文件
              // this.upload('frontImg')
              console.log('可以重新上传文件')
              console.log(this.$refs.newUpload)
              this.$refs['newUpload'].submit()//上传文件
            }
          })
        },
        //删除公告内容
        deleteBanner(id){
          let that = this
          this.$confirm("此操作将删除当前轮播图内容, 是否继续?", "提示", {
				     confirmButtonText: "确定",
				     cancelButtonText: "取消",
				     type: "warning",
			    })
			    .then(() => {
            bannerDel({slider_id:id}).then(res=>{
              console.log('是否同意删除当前轮播图',res.data)
              if(res.data.code == '1'){
                  that.$message.success('删除成功')
                  that.getBannerList()
              }else{
                  that.$message.error(res.info)
              }
            })
			    })
			    .catch(() => {
			    	this.$message({
			    		type: "info",
			    		message: "已取消操作",
			    	});
			    });
        },
        //查看意见描述
        readInfo(info){
          this.showInfoModel = true
          this.imgContent = info
        },
        //获取公共列表
        getBannerList(){
            let that = this
            let tabDat = {}
            for (let item in this.sendTab) {
              if (that.sendTab[item] != '' && that.sendTab[item] != '999') {
                tabDat[item] = that.sendTab[item]
              }
            }
            bannerList(tabDat).then(res=>{
                console.log('轮播图',res)
                let {list,page,count,limit}=res.data.data
                console.log(res.data.code,list,page,count,limit)
                if(res.data.code == '1' && list){
                  this.rows = count
                  this.dataList = list
                }else if(res.data.code=='1'&&res.data.data.csv_file){
                  window.open(res.data.data.csv_file)
                  this.sendTab.limit=this.rows
                }
            })
        },
        //提交
        submitForm(formName){
          this.$refs[formName].validate((valid)=>{
            if(valid){
              //发送添加banner的方法
              this.sendRequestAddBanner(formName)
            }else{
              console.log('error submit')
              return false
            }
          })
        },
        //sendRequestAddBanner
        sendRequestAddBanner(formName){
          bannerAdd(this.ruleForm).then(res=>{
            console.log('添加banner',res.data)
            if(res.data.code == '1'){
              this.$message.success('添加成功')
              this.addDialogVisible = false
              this.imageUrl = ''
              this.getBannerList()
            }else{
              this.$message.error(res.info)
              this.imageUrl = ''
              this.$refs[formName].resetFields()
            }
          })
        },
        //导出表格
        exportData(){
          this.sendTab.limit = 9999
          this.sendTab.page = 1
          this.getNoticeList()
        },
        /* 附件*/
        showpicture(arr) {
          this.showImgModel = true
          this.imgContent = arr
        },
        closeImgModel() {
          this.showImgModel = false
        },
        closeInfoModel() {
          this.showInfoModel = false
        },
        //退款成功，刷新列表
        changeList(){
            this.getFinanceRefund()
        },
        //关闭编辑公告弹窗
        closeEditModel(){
          this.editDialogVisible = false
        },
        closeAddDialog(){
          this.addDialogVisible = false
        },
        //导出
        exportTab(){
          this.sendTab.limit = 9999
          this.sendTab.page = 1
          this.getNoticeList()
        }
    },
    components:{
        Pagination
    }
}
</script>
<style>
#bannerManage .header_search .el-input__inner {
    height: 20px !important;
    border: none !important;
    padding: 0 !important;
  }

  /* 添加站长弹窗---开始*/
  #bannerManage #addBanner .el-dialog {
    width: 780px;
    border-radius: 4px;

  }

  #bannerManage #addBanner .el-dialog__header {
    text-align: left;
  }

  #bannerManage #addBanner .el-dialog__header .el-dialog__title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
  }

  #bannerManage #addBanner .el-dialog__body {
    padding: 24px;
    padding-top: 0 !important;
  }

  #bannerManage #addBanner .el-dialog__footer {
    text-align: center !important;
  }

  #bannerManage #addBanner .el-dialog__footer button {
    width: 260px;
    height: 40px;
    background: #4E9F5B;
    border-radius: 4px;
    color: white;
  }

  #bannerManage #addBanner #form .el-input__inner {
    border: none !important;
  }

  #bannerManage #addBanner #form .el-form-item {
    border-bottom: 1px solid #F5F6F7 !important;
  }

  #bannerManage #addBanner #form .el-form-item__label {
    text-align: left !important;
  }

  /* 添加站长弹窗---结束*/
  /* 上传图片 */
  #bannerManage .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  #bannerManage .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  #bannerManage .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 320px;
    height: 160px;
    line-height: 160px;
    text-align: center;
  }

  #bannerManage .avatar {
    width: 320px;
    height: 160px;
    display: block;
  }
</style>